import React from "react";

function Section({ children, className, ...props }) {
  return (
    <section
      className={`max-w-screen-2xl px-4 mx-auto md:px-8 w-full ${className}`}
      {...props}
    >
      {children}
    </section>
  );
}

export default Section;
