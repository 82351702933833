import React, { useState } from "react";
import Slider from "infinite-react-carousel";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import Button from "../components/button";
import { H1, H3 } from "../components/typography";
import foodDesktop from "../images/food-desktop.png";
import foodMobile from "../images/food-mobile.png";
import hopeAndMainLogo from "../images/hope-and-main.png";
import hopeAndMainLogoWhite from "../images/hope-and-main-white.svg";
import KeepMePosted from "../components/keep-me-posted";

const quotesData = [
  {
    author: "Ava K.",
    text: "When I heard about this platform I was very excited because as a mom of four, it’s quite hard sometimes to juggle working full time and feeding my whole family. My husband and I really love the fact that we will be able to have a weekly subscription for large family meals and can just have them delivered a few different times during the week.",
  },
  {
    author: "Max E.",
    text: "I really love specialty foods and I’m always excited when I can get them directly from the source, like at a fair or a farmer’s market. I love the fact that I will be able to get orders of items like kimchi from a local maker through NearPlate and still have that personalized feeling I get at the farmer’s market.",
  },
  {
    author: "Shelly N.",
    text: "I really love sampling and trying pastries and baked goods but sometimes these online stores can be so difficult to navigate. Nearplate looks easy to use and will allow me to look up what I need and order quickly. It will also remind me when I need to go pick up my treats.",
  },
];

const testimoniesData = [
  {
    author: "Guetty Antiste",
    image: "/images/guetty-testimony.png",
    occupation: "Culinary Artiste",
    text: "I'm Guetty Antiste, owner and founder of Antiste Eatery, a gourmet empanadery. My love for cooking stems from making people happy by simply feeding them delicious things. Two years ago, I started connecting and tapping into my Haitian and Martiniquan heritage and I felt like I really was starting to flourish as a small business owner. When Covid hit, and everything shut down, I received a lot of inquiries about delivering and shipping empanadas. I quickly realized two things -  Antiste Eatery could sustain me full-time, but I was not equipped to handle all the logistics that went behind managing the business. That’s when I discovered NearPlate. NearPlate allows me the flexibility to handle how orders are processed, picked up, and shipped. More importantly, the resources to successfully manage my profit margins.",
  },
  {
    author: "Viggo Johansson",
    image: "/images/person-5.png",
    occupation: "Baker",
    text: "My name is Viggo Johansson and I come from a long line of bakers. When my parents moved to the United States, I was just 10 years old. I grew up watching my parents bake from our little kitchen for all of the community events. I specifically loved making semlor (traditional Swedish cream buns). My dream was to open up my own bakery back in Sweden when I got older but life took over and I was content with baking for family and friends until I found NearPlate. The fact that NearPlate can connect me with a commercial kitchen means I can rekindle that dream. I will be able to bake, package and sell my selmor through NearPlate all while doing it at an affordable price.",
  },
  {
    author: "Kitty Blanchard",
    image: "/images/person-3.png",
    occupation: "Chef",
    text: "My name is Kitty Blanchard and I am a first-generation Korean-American and a retired mechanical engineer. Three years ago, I started making kimchi as a hobby. One day, my daughter shared my kimchi with some of her friends and they asked if I had considered selling it. I must say I didn't know where I would even begin! My daughter told me about NearPlate, a platform that would help manage my e-commerce front store. I don't have the fear of not meeting demands because I’m able to make my 40 jars a week and appoint days for pick-ups. I love the fact that I also don't have to figure it out by myself. There is a team ready to help me each step of the way. ",
  },
  {
    author: "Mercury Rhodes",
    image: "/images/person-1.png",
    occupation: "Home Cook/Foodpreneur",
    text: "I am Mercury Rhodes and I just completed my second year of college. The pandemic was really rough on my family. I started cooking for my family of seven as a stress reliever and surprisingly found out I am really great at it.  When my mom lost her job due to the pandemic, I started thinking of ways I could help chip in financially. I knew I wanted to be able to make income from selling my food safely but with no access to a commercial kitchen and new clients I wasn't sure how I would make that work. While on Instagram, I discovered NearPlate. A platform where I could build up clientele, have an online storefront, and connect with local commercial kitchens where I can schedule hours and produce large format meals.",
  },
];

const testimonies = testimoniesData.map((testimony, idx) => (
  <Testimony key={idx} {...testimony} />
));

function Testimony({ author, text, occupation, image }) {
  const [readMore, setReadMore] = useState(false);
  const newText = readMore ? text : `${text.slice(0, 310)}...`;

  return (
    <div
      className={
        "bg-white rounded md:shadow max-w-md p-5 space-y-3 text-m mb-5"
      }
    >
      <div
        className={
          "rounded rounded-full overflow-hidden object-cover w-24 h-24"
        }
      >
        <img className={"w-full h-full"} src={image} alt={author} />
      </div>
      <div>
        <h4 className={"text-lg font-bold text-dark-200"}>{author}</h4>
        <p className={"text-dark-100"}>{occupation}</p>
      </div>
      <div className={"space-y-2 text-sm"}>
        <p>{newText}</p>
        <button
          onClick={() => setReadMore(!readMore)}
          className={"font-bold"}
          style={{ color: "#83888E" }}
        >
          Read {!readMore ? "More" : "Less"}
        </button>
      </div>
    </div>
  );
}

function IndexPage() {
  return (
    <Layout>
      <SEO
        description={
          "NearPlate will enable food lovers to access a world of culinary delights from an inclusive community of foodpreneurs who are right in their own backyards."
        }
        title="Home"
      />

      <Section className={"my-12 md:my-16"}>
        <div
          className={
            "flex flex-col md:flex-row space-between items-start space-y-8 md:space-y-0 md:space-x-8 lg:space-x-24 xl:space-x-32"
          }
        >
          <div className={"md:w-1/2 md:mr-8 md:mt-8"}>
            <div className={"space-y-6"}>
              <H1>
                NearPlate will enable{" "}
                <span className={"text-primary"}>food lovers</span> to access a
                world of culinary delights from an inclusive community of{" "}
                <span className={"text-primary"}>foodpreneurs</span> who are
                right in their own backyards.
              </H1>
              <p className={"text-dark-100 text-md md:text-lg"}>
                By matching with certified kitchens and providing an affordable
                platform to connect eaters with makers, we will create a more
                accessible and equitable local food system.
              </p>
              <KeepMePosted>
                <Button className={"w-full md:w-auto"}>Keep Me Posted!</Button>
              </KeepMePosted>
            </div>
            <div
              className={
                "flex justify-center md:justify-start items-center space-x-3 mt-8 md:mt-16"
              }
            >
              <p className={"font-medium"}>In partnership with:</p>
              <img
                alt="Hope and main"
                className={"h-12"}
                src={hopeAndMainLogo}
              />
            </div>
          </div>
          <div className={"md:w-1/2 relative -mx-4"}>
            <img
              alt="Food Image"
              className="hidden md:block"
              src={foodDesktop}
            />
            <img alt="Food Image" className="md:hidden" src={foodMobile} />
          </div>
        </div>
      </Section>

      <section
        id={"stories"}
        className={"py-16 md:py-24"}
        style={{ backgroundColor: "rgba(221, 241, 241, 0.16)" }}
      >
        <div
          className={
            "max-w-screen-2xl px-4 md:px-8 flex flex-col items-center mx-auto space-y-4"
          }
        >
          <H3 className={"max-w-3xl text-left md:text-center"}>
            Enabling foodpreneurs to sell their food products by easily pairing
            them with local commercial kitchens
          </H3>

          <div className={"flex flex-col space-y-6 w-full"}>
            <div className={"md:hidden w-full mx-auto"}>
              <Slider
                autoPlay
                slidesToShow={1}
                speed={3}
                arrows={false}
                adaptiveHeight
                dots
              >
                {testimonies}
              </Slider>
            </div>
            <div className={"hidden sm:block lg:hidden w-full mx-auto"}>
              <Slider
                autoPlay
                slidesToShow={2}
                speed={3000}
                arrows={false}
                adaptiveHeight
                dots
              >
                {testimonies}
              </Slider>
            </div>

            <div className={"hidden xl:grid grid-cols-4 gap-8 w-full mx-auto "}>
              {testimonies}
            </div>
          </div>
        </div>
      </section>

      <section className={"bg-dark py-16"} id={"partners"}>
        <div
          className={
            "max-w-xl md:max-w-3xl flex flex-col md:flex-row items-center mx-auto space-y-8 md:space-y-0 md:space-x-16 px-4"
          }
        >
          <img
            alt="Hope and main white"
            className=""
            src={hopeAndMainLogoWhite}
          />

          <div className={"text-center md:text-left text-white space-y-4"}>
            <div className={"space-y-2"}>
              <h5 className={"text-base font-bold"}>
                NearPlate is partnering with Hope & Main, one of the top
                culinary incubators in the United States.
              </h5>
              <p>
                Our partners&apos; mission is to empower an inclusive community
                of entrepreneurs to jumpstart and cultivate thriving food
                businesses that are the foundation of a more just, sustainable
                and resilient local food economy.
              </p>
            </div>
            <a
              href={"https://makefoodyourbusiness.org"}
              target="_blank"
              className={"inline-flex items-center font-bold space-x-3"}
              rel="noreferrer"
            >
              <span className={"block"}>Learn More </span>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.66255 6.3496L5.00447 6L4.66255 5.6504L0.681006 1.57937C0.439665 1.3326 0.439665 0.927186 0.681006 0.680421C0.916279 0.43986 1.29384 0.43986 1.52911 0.680421L6.33697 5.59634C6.55459 5.81885 6.55395 6.18237 6.33742 6.4032L6.33697 6.40366L1.52911 11.3196C1.29384 11.5601 0.916279 11.5601 0.681006 11.3196C0.439665 11.0728 0.439665 10.6674 0.681006 10.4206L4.66255 6.3496Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className={"py-24 bg-quotes bg-cover"}>
        <div
          className={
            "max-w-lg md:max-w-xl flex flex-col items-center mx-auto text-center space-y-4 px-4 "
          }
        >
          <H3>What others say</H3>
          <svg
            width="30"
            viewBox="0 0 23 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.708 15.856C15.8787 15.856 14.5533 15.2027 13.732 13.896C13.3587 13.336 13.06 12.6453 12.836 11.824C12.612 11.0027 12.5 10.144 12.5 9.248C12.5 7.30667 12.9853 5.552 13.956 3.984C14.964 2.416 16.4387 1.184 18.38 0.287997L18.884 1.296C17.8013 1.744 16.8493 2.43466 16.028 3.368C15.244 4.30133 14.7773 5.25333 14.628 6.224C14.516 6.672 14.46 7.10133 14.46 7.512C14.46 7.88533 14.516 8.24 14.628 8.576C15.4493 7.68 16.532 7.232 17.876 7.232C19.1827 7.232 20.2467 7.624 21.068 8.408C21.8893 9.15466 22.3 10.2 22.3 11.544C22.3 12.8133 21.8707 13.8587 21.012 14.68C20.1533 15.464 19.052 15.856 17.708 15.856ZM6.172 15.856C4.34267 15.856 3.01733 15.2027 2.196 13.896C1.82267 13.336 1.524 12.6453 1.3 11.824C1.076 11.0027 0.964 10.144 0.964 9.248C0.964 7.30667 1.44933 5.552 2.42 3.984C3.428 2.416 4.90267 1.184 6.844 0.287997L7.348 1.296C6.26533 1.744 5.31333 2.43466 4.492 3.368C3.708 4.30133 3.24133 5.25333 3.092 6.224C2.98 6.672 2.924 7.10133 2.924 7.512C2.924 7.88533 2.98 8.24 3.092 8.576C3.91333 7.68 4.996 7.232 6.34 7.232C7.64667 7.232 8.71067 7.624 9.532 8.408C10.3533 9.15466 10.764 10.2 10.764 11.544C10.764 12.8133 10.3347 13.8587 9.476 14.68C8.61733 15.464 7.516 15.856 6.172 15.856Z"
              fill="#E47401"
            />
          </svg>

          <div className={"w-full mx-auto"}>
            <Slider autoPlay speed={3000} arrows={false} dots>
              {quotesData.map(({ text, author }, idx) => (
                <div key={idx}>
                  <div className={"max-w-xl w-full mx-auto mb-3"}>“{text}”</div>
                  <h5 className={"text-base font-bold"}>- {author}</h5>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
