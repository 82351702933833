import React from "react";

export const H1 = ({ children, className }) => (
  <h1
    className={`text-3xl lg:text-5xl mb-4 text-dark leading-loose tracking-tight ${className}`}
    style={{ fontWeight: 800, lineHeight: 1.15 }}
  >
    {children}
  </h1>
);

export const H2 = ({ children, className }) => (
  <h2
    className={`text-2xl sm:text-3xl md:text-4xl font-medium mb-4 leading-loose tracking-tight ${className}`}
  >
    {children}
  </h2>
);

export const H3 = ({ children, className }) => (
  <h3
    className={`text-xl sm:text-2xl xl:text-3xl font-bold mb-4 leading-tight ${className}`}
    style={{ fontWeight: 800 }}
  >
    {children}
  </h3>
);

export const P = ({ children, className }) => (
  <p className={`text-base md:text-md xl:text-lg ${className}`}>{children}</p>
);

export const Caption = ({ children, className }) => (
  <span className={`text-sm md:text-base ${className}`}>{children}</span>
);
